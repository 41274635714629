import React, { useCallback, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { useLocation, useParams } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

import { triggerPlausibleEvent } from 'utils/plausible';

import NotFound from 'components/pages/NotFound/NotFound';

import { plausibleEvents } from 'components/config/plausibleEvents';
import { data as tutorialsData } from 'components/config/tutorials';

import { ReactComponent as CheckMark } from 'images/Icons/CheckMark.svg';
import Preload from 'images/Tutorial/preload.jpg';

import styles from './Tutorials.module.scss';

const Tutorials = () => {
  const { system } = useParams();
  const { hash } = useLocation();

  const data = tutorialsData[system];

  const scrollToHash = (hash) => {
    document
      ?.getElementById(hash.replace('#', ''))
      ?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleWatchEvent = useCallback(
    (name) => () =>
      triggerPlausibleEvent({
        name: plausibleEvents.watchTutorial,
        props: {
          'video-name': name,
        },
      }),
    [],
  );

  useEffect(() => {
    if (hash) {
      setTimeout(() => scrollToHash(hash), 0);
    }
  }, [hash]);

  if (!data) {
    return <NotFound />;
  }

  return (
    <div className={styles.wrapper}>
      {system === 'bonusHouse' ? (
        <div className={styles.banner}>
          <img src={data.logo} className={styles.logo} alt={'logo'} />
          <div className={styles.intro}>{data.description}</div>
        </div>
      ) : (
        <div className={styles.header}>
          <div className={styles.title}>{data.title}</div>
        </div>
      )}
      {data.navigation && (
        <div className={styles.navigation}>
          {data.navigation.map((item) => (
            <HashLink
              key={item.anchorId}
              to={`#${item.anchorId}`}
              className={styles.navigationItem}
              smooth
            >
              {item.icon} {item.name}
            </HashLink>
          ))}
        </div>
      )}
      <div>
        {data.contents?.map((content) => (
          <div
            key={content.anchorId}
            id={content.anchorId}
            className={styles.content}
          >
            {content.name && (
              <div className={styles.contentTitle}>
                {content.icon} {content.name}
              </div>
            )}
            <div className={styles.contentDescription}>
              {content.description}
            </div>
            <div className={styles.contentItems}>
              {content.items?.map((item, index) => (
                <div key={index} className={styles.contentItem}>
                  <CheckMark /> {item}
                </div>
              ))}
            </div>
            {content.mediaUrl && (
              <div className={styles.contentMedia}>
                <div className={styles.youtube}>
                  <ReactPlayer
                    url={content.mediaUrl}
                    width="100%"
                    height="100%"
                    controls={true}
                    playing={true}
                    light={true}
                    playIcon={<img src={Preload} alt="preload" />}
                    onStart={handleWatchEvent(content.name)}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tutorials;
