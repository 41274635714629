import React, { useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import { setDialogData } from 'store/slices/downloadMaterials';

import { triggerPlausibleEvent } from 'utils/plausible';

import { CircularProgress } from '@mui/material';

import Button from 'components/UI/Button/Button';

import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  structure: PropTypes.shape({}).isRequired,
  downloadFileName: PropTypes.string.isRequired,
  folderPath: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['Promo', 'Game', 'Bingo']).isRequired,
  gamesData: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(
      PropTypes.shape({ name: PropTypes.string, data: PropTypes.shape({}) }),
    ),
  ]),
  materials: PropTypes.shape({}),
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf(['Primary', 'Base', '']),
  trackEvent: PropTypes.exact({
    name: PropTypes.string,
    props: PropTypes.object,
  }),
  provider: PropTypes.string,
  languageLabel: PropTypes.string,
  fetchMaterials: PropTypes.func,
  selectedItems: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
};

const DownloadMaterials = ({
  children,
  disabled = false,
  variant = '',
  materials = null,
  structure,
  downloadFileName,
  folderPath,
  type,
  gamesData,
  provider = null,
  trackEvent = null,
  languageLabel = 'Languages',
  fetchMaterials,
  selectedItems,
  loading,
}) => {
  const dispatch = useDispatch();

  const cachedMaterialsRef = useRef({});

  const getActualMaterials = useCallback(async () => {
    if (!fetchMaterials) return materials;

    const cacheKey = selectedItems ? selectedItems.join('_') : type;

    if (cachedMaterialsRef.current[cacheKey])
      return cachedMaterialsRef.current[cacheKey];

    const data = await fetchMaterials();
    cachedMaterialsRef.current[cacheKey] = data;

    return data;
  }, [fetchMaterials, materials, selectedItems, type]);

  const props = {
    onClick: useCallback(
      async (e) => {
        e.stopPropagation();
        e.preventDefault();

        triggerPlausibleEvent(trackEvent);

        const actualMaterials = await getActualMaterials();

        dispatch(
          setDialogData({
            materials: actualMaterials,
            structure,
            downloadFileName,
            folderPath,
            type,
            gamesData,
            provider,
            languageLabel,
            trackingPage: trackEvent.props.page,
          }),
        );
      },
      [
        trackEvent,
        getActualMaterials,
        structure,
        downloadFileName,
        folderPath,
        type,
        gamesData,
        provider,
        languageLabel,
      ],
    ),
  };

  if (disabled) {
    props.disabled = disabled;
  }

  if (variant !== 'Base') {
    props.startIcon = <DownloadIcon />;
    props.variant = `icon${variant}`;
  }

  if (loading) {
    props.startIcon = <CircularProgress size={32} />;
    props.disabled = true;
  }

  return <Button {...props}>{children}</Button>;
};

DownloadMaterials.propTypes = propTypes;
export default DownloadMaterials;
