import { getRandomInt } from 'utils/number';

import { isSafari } from 'config/client';

import Background from 'images/LisbonDialog/background.jpg';
import CardsMp4 from 'images/LisbonDialog/cards.mp4';
import Cards from 'images/LisbonDialog/cards.webm';
import MagicBallMp4 from 'images/LisbonDialog/magic_ball.mp4';
import MagicBall from 'images/LisbonDialog/magic_ball.webm';

export const paperStyles = {
  height: 630,
  width: '100%',
  maxWidth: 825,
  background: `url(${Background}) #0a0126 no-repeat center`,
  border: '2px solid #0099FA',
  borderRadius: '12px',
  boxShadow: `0 4px 30px 5px #0032FA`,
  boxSizing: 'border-box',
};

export const steps = [
  {
    title: 'Experience the magic of Evoplay at the SBC Lisbon!',
    actionTitle: 'Dive into magic',
    video: !isSafari ? MagicBall : MagicBallMp4,
  },
  {
    title: 'Tap to see what the future holds for your business!',
    actionTitle: 'Find out my business future',
    video: !isSafari ? Cards : CardsMp4,
  },
  {
    title: 'Here is your prediction!',
    actionTitle: 'Check out more magic',
  },
];

const cardsData = [
  {
    title: 'MAG OF LUCK',
    text: 'Let the Mag of Luck guide your path — during your next coffee break, seize the opportunity for a partnership that promises millions in profits!',
  },
  {
    title: 'MAGIC WHEEL',
    text: 'Magic Wheel predicts the unfolding of fate, revealing new opportunities and events that promise positive changes in your life.',
  },
  {
    title: 'MYSTIC STRANGER',
    text: `From now on, fate invites you to start a new journey. Be open and honest, and you'll find happiness in the opportunities that lie ahead.`,
  },
  {
    title: 'PRIESTESS OF LIGHT',
    text: `You're entering a highly successful period. Trust your intuition and the Universe to speed your journey to success!`,
  },
  {
    title: 'EMPEROR',
    text: 'Today, fate has prepared a pleasant surprise for you as a gift for your perseverance. Look around for a sign that will guide you to your next reward!',
  },
  {
    title: 'EMPRESS',
    text: 'Fate rewards your adventurous spirit and courage with abundance and luck. Seek a guiding sign to flourish!',
  },
  {
    title: 'ORACLE OF WISDOM',
    text: 'The dedication to values can be both limiting and rewarding. For your commitment, expect gifts from this magical moment!',
  },
  {
    title: `FORTUNE'S FAVOURITE`,
    text: 'Luck largely depends on a timely decision. Listen to yourself and bet on what you truly believe in. Your success will follow swiftly!',
  },
  {
    title: 'CHARIOT OF ADVANCEMENT',
    text: `It's time to charge forward! You have all the real resources you need, along with mystical help, to succeed!`,
  },
  {
    title: 'POWER OF LUCK',
    text: 'Your strength lies in your ability to find compromises and negotiate effectively. Leverage these skills to achieve better results in your business endeavours.',
  },
  {
    title: 'GUIDING LIGHT',
    text: 'The fish of luck has come into your hands. Fabulous events in your life begin right now, so be ready!',
  },

  {
    title: 'LUCKY SPINS',
    text: 'Enjoy how unplanned events bring unexpected gifts of fate and prepare to reap rewarding outcomes for your readiness to anticipate!',
  },
  {
    title: 'UNIVERSAL GRACE',
    text: 'No matter what happens, luck will favour you! But to uphold universal justice, share part of your fortune generously!',
  },
  {
    title: 'MYSTIC DELIGHT',
    text: 'Mystic forces are bringing a powerful financial surge your way. Get ready to ride to prosperity!',
  },
  {
    title: 'TRIUMPHANT PATH',
    text: 'The day of business triumph has arrived! Each goal you achieve will triple impact, paving the way for unprecedented success!',
  },
  {
    title: 'HIDDEN GOLDMINE',
    text: 'You will find prosperity in the most unexpected places — just remember to look around!',
  },
  {
    title: 'TRIPLE TRIUMPH',
    text: `Over the next three months, you'll finalise a contract that will bring you not only significant profits but also valuable new connections`,
  },
  {
    title: 'BENEATH THE VEIL',
    text: 'Discover a hidden opportunity today that promises both wealth and a new ally, all during your next lunch break!',
  },
  {
    title: 'TWIST OF FATE',
    text: `A mystical turn awaits in your journey, unveiling new paths and promising positive shifts toward your dreams' realisation.`,
  },
  {
    title: 'FENIX OF FORESIGHT',
    text: 'Your next cup of coffee will contain a magical elixir. Make a wish with all your heart and repeat it three times for it to come true!',
  },
  {
    title: 'ROLL TO LUCK',
    text: 'Throughout your work journey, pay attention to those in red. One of them will bring you good luck!',
  },
  {
    title: 'MYSTIC PATHWAYS',
    text: 'Smile at the three people who capture your attention the most and await a magical twist of fortune at midnight!',
  },
  {
    title: 'KINDNESS GLOW',
    text: 'Remember, luck favours not just the brave but also the kind-hearted! Spread encouragement, and happiness will follow you everywhere!',
  },
  {
    title: 'SUGAR RUSH',
    text: 'Get ready for big wins! Your lucky number for the next adventure is 3 — keep an eye out for opportunities and seize success!',
  },
  {
    title: `ANGELS' HINT`,
    text: 'Your upcoming conversation with partners will be fateful! Choose your words wisely and stay alert for magical outcomes!',
  },
  {
    title: 'BRIGHT SPARK',
    text: 'The next contract you sign will bring in profits faster than magic can unfold!',
  },
  {
    title: 'COSMIC CLUE',
    text: `You're about to stumble upon a magically inspired business idea so remarkable, it's destined to make headlines. Start searching for it now!`,
  },
  {
    title: 'KNIGHT OF PROSPERITY',
    text: `Your next business deal will be so profitable, you'll need to raise a toast! Luck has already worked its magic to make it come true!`,
  },
  {
    title: 'CLOVER BLESSING',
    text: 'Your next partnership will be as lucky as finding a four-leaf clover. To make this luck come true, enjoy a cup of tea right now!',
  },
  {
    title: 'SPICY SURGE',
    text: `Your company's profits will skyrocket, potentially boosting your personal gains. Celebrate it with a well-deserved vacation!`,
  },
  {
    title: 'UNIVERSAL GIFT',
    text: `Anticipate a gift from the universe, a reminder that miracles exist! It will be bigger than any birthday surprise you've ever received!`,
  },
  {
    title: 'LUCKY 3',
    text: `The third person with whom you exchange business cards today could bring you luck! Use all your charm to win this person's favour!`,
  },
  {
    title: 'MAGIC KICK',
    text: 'The stars are smiling upon you, favouring your path. Let their guiding light inspire you to take bold actions!',
  },
  {
    title: `SHADOW'S GIFT`,
    text: 'As dusk falls, a hidden opportunity will reveal itself to you. Embrace it with open arms; it holds the promise of both wealth and a valuable new ally',
  },
  {
    title: 'GOLD RUSH',
    text: `Beneath the moon's glow, a lucrative contract awaits you, promising not only a blossoming new partnership but also significant dividends!`,
  },
  {
    title: `SOUL'S WHISPER`,
    text: 'Trust in your intuition today, as it will steer you towards a chance encounter that promises both financial gains and personal growth',
  },
  {
    title: `LUCK'S PULL`,
    text: 'Luck is smiling upon you! Watch attentively for a mysterious invitation or unexpected message that could unlock doors to wealth and new beginnings.',
  },
  {
    title: 'BRIGHT FUTURE',
    text: 'Luck is on your side! Watch for a mysterious invitation or message that could open doors to wealth and new beginnings.',
  },
  {
    title: `RAINBOW'S GLOW9`,
    text: 'Prepare for a windfall of prosperity! The universe is aligning to reward your hard work with financial abundance.',
  },
  {
    title: 'WISH UPON STARS',
    text: `Everything we believe in comes true. Close your eyes, imagine your deepest wish becoming reality, and say, 'By star and moon, I call this boon!'`,
  },
  {
    title: 'BOUNTY HARVEST',
    text: 'Your next business trip will be a blend of profit and delight. Be open to magic opportunities!',
  },
  {
    title: 'ANGELIC SPARK',
    text: 'Make a wish now, knock on wood 3 times, smile at the first person you see, and await the Universe to make it happen. The angels are on it!',
  },
  {
    title: 'JACKPOT SIGNALS',
    text: `Your passion and luck will combine for a winning performance ahead! Curious about what's in store? In 10 days, you'll receive a sign`,
  },
  {
    title: 'LUCKY 777',
    text: `You'll discover prosperity in places where luck unfolds, bringing jackpots and big wins your way. Stay tuned for the thrilling twist!`,
  },
  {
    title: `FORTUNE'S DANCE`,
    text: `You'll find riches where luck dances, bringing big wins and jackpots. Stay tuned for the delightful surprise!`,
  },
  {
    title: `HEAVEN'S SMILE`,
    text: `If you keep seeing the same sign over and over, it's the Universe nudging you like a persistent friend. Take the hint and go with the flow!`,
  },
  {
    title: 'ABRACADABRA',
    text: `Prepare for a promotion that'll propel your career forward faster than you can say "abracadabra!"`,
  },
  {
    title: `SESAME'S MIRACLE`,
    text: `Get ready for a windfall of wins that'll come knocking faster than you can say "open sesame!" Hold on tight for a magic carpet ride to success!`,
  },
  {
    title: 'LUCKY SHIFT',
    text: `A fortune bigger than a genie's treasure is on the horizon. Your magic lamp moment is near — get ready for your wishes to come true!`,
  },
  {
    title: `DRAGON'S EMBRACE`,
    text: `A wealth as vast as a dragon's hoard awaits you. Keep your eyes open for the golden key to unlock your treasure trove!`,
  },
  {
    title: `SKY'S GLITTER`,
    text: `Your fortune is hidden in the enchanting Neuschwanstein Castle in Germany. Follow the trail of sparkling clues to find the key that opens the door to your treasure!`,
  },
  {
    title: `SAPPHIRE'S SECRET`,
    text: 'Keep a deep sapphire amulet with the number 9 close and watch everything align!',
  },
  {
    title: 'BELLA FORTUNA',
    text: 'Your fortune awaits in Sforza Castle, Italy. Follow the sparkling clues to unlock your treasure!',
  },
  {
    title: `FORTUNE'S FIN`,
    text: 'Wear a golden fish amulet on your left leg to attract luck into both your personal and business endeavours.',
  },
  {
    title: 'ROYAL SHINE',
    text: 'Keep a ruby amulet close to your heart for wisdom, clarity, and astute decision-making in both personal and business interactions.',
  },
  {
    title: 'GOLDEN AURA',
    text: `Wear a Feng Shui talisman — a gold frog — to invite prosperity and great fortune into your life and your family's`,
  },
  {
    title: 'RISING STAR',
    text: 'Your dedication and hard work will soon pay off with a promotion that exceeds your expectations. Get ready to shine!',
  },
  {
    title: 'NEW BEGINNINGS',
    text: 'Embrace a new fitness routine or wellness practice. Your commitment will lead to renewed energy and vitality.',
  },
  {
    title: 'ESCAPE QUEST',
    text: `Pack your bags for an unexpected journey to a place you've always dreamt of visiting. New adventures await!`,
  },
  {
    title: 'MIRACLE BREATH',
    text: 'An old friendship will rekindle into something meaningful and long-lasting. Look out for unexpected moments that strengthen your bond.',
  },
  {
    title: 'MIRACLE BREATH',
    text: 'Channel your creativity into a new project or hobby. Inspiration will flow effortlessly, leading to artistic fulfilment and success.',
  },
  {
    title: 'MIRACLE BREATH',
    text: 'Get ready for a chance encounter that could change your career path forever. Look out for an opportunity with someone wearing a distinctive hat.',
  },
  {
    title: 'THE JOYNESS',
    text: 'Rediscover an old hobby to reignite your passion and open up new opportunities. Find what made you happy as a child.',
  },
  {
    title: 'FORTUNE TELLER',
    text: 'Trust your gut when making spontaneous decisions — it could lead to unexpected rewards. Just take a moment to go with the flow.',
  },
  {
    title: `WISDOM'S GEM`,
    text: `Your next trip to the bookstore could reveal a book filled with wisdom for your life journey. Don't miss out on reading it!`,
  },
  {
    title: 'LUCKY TURN',
    text: `Embrace sudden changes — they could lead to unforgettable experiences that enrich your life. Don't hesitate when opportunity knocks soon`,
  },
  {
    title: 'MAJESTY CASE',
    text: 'Doing a kind act today could bring unexpected joy into your life. Relax and be yourself; authenticity paves the way for timely actions and rewards.',
  },
  {
    title: 'SURPRISE REWARD',
    text: 'Be yourself; honesty brings genuine rewards. You might soon see how your kindness to someone six months ago turns into a pleasant surprise.',
  },
  {
    title: 'MIDNIGHT CHANGE',
    text: 'Dive into your day like Cinderella at midnight — luck is brewing, with a glass slipper moment awaiting!',
  },
  {
    title: 'EPIC TWIST',
    text: 'Just like in the movies, today is your chance to rewrite the script of luck in your favour. Keep an eye out for the thrilling plot twist!',
  },
  {
    title: 'LAMP OF DREAMS',
    text: 'Channel your inner Aladdin and rub the lamp of opportunity. A genie-like stroke of luck is ready to grant your wishes!',
  },
  {
    title: `CAPTAIN'S HOARD`,
    text: 'In the magical forest of life, follow the breadcrumbs of fate to discover a treasure trove of opportunities waiting just for you.',
  },
  {
    title: `WIZARD'S KISS`,
    text: `Today's luck is straight out of a fairy tale — where frogs turn into princes and dreams come true with a kiss of serendipity, painting your life in vibrant hues.`,
  },
  {
    title: 'CHARM OF CHEER',
    text: `Unleash the magic of laughter — it's the secret ingredient to turning unlucky moments into whimsical wins anytime and anywhere!`,
  },
  {
    title: 'GRAIL OF WONDERS',
    text: `Embrace today's adventure like a hero on a quest for the Holy Grail of luck. Adventure awaits at every turn of the labyrinth!`,
  },
  {
    title: 'RHYTHM OF TRIUMPH',
    text: `Navigate life's challenges with the grace of a ballroom dancer, where luck is your perfect partner, ready to lead you to success!`,
  },
  {
    title: 'STAR PATH',
    text: 'Tonight, stardust lights your path, bringing luck and magic. Embrace the night and let your dreams soar among the stars!',
  },
  {
    title: 'TRIPLE LUCK',
    text: 'When the number 3 appears thrice, fortune whispers: "Your next lottery ticket brings wins."',
  },
];

const cardIndex = getRandomInt(1, cardsData.length);

export const predictionCard = {
  src: `https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Promo+materials/sbc_lisbon/taro_img/card_${cardIndex}.jpg`,
  ...cardsData[cardIndex],
};
