import React from 'react';

import PropTypes from 'prop-types';

import { Button as MuiButton, styled } from '@mui/material';

const propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'icon',
    'iconPrimary',
    'iconGradient',
    'text',
  ]),
};

const PrimaryButton = styled(MuiButton)(() => ({
  fontFamily: 'inherit',
  color: '#FFFFFF',
  backgroundColor: '#0099FA',
  borderRadius: 12,
  fontSize: 14,
  lineHeight: '22px',
  fontWeight: 500,
  textTransform: 'none',
  gridArea: 'btn',
  border: 'none',
  height: 40,
  '&:hover': {
    backgroundColor: '#0069FF',
    borderWidth: 0,
  },
  '&.Mui-disabled': {
    backgroundColor: '#EBEBEC',
    borderWidth: 0,
    color: '#888B8D',
  },
}));

const SecondaryButton = styled(PrimaryButton)(() => ({
  color: '#0099FA',
  backgroundColor: '#FFFFFF',
  border: '1px solid #0099FA',
  '&:hover': {
    backgroundColor: 'rgba(0, 153, 250, 0.1)',
    borderWidth: 1,
  },
  '&.Mui-disabled': {
    backgroundColor: '#FFFFFF',
    borderWidth: 1,
    borderColor: 'rgba(136, 139, 141, 0.5)',
    color: 'rgba(136, 139, 141, 0.5)',
    '& svg path': {
      stroke: 'rgba(136, 139, 141, 0.5)',
    },
  },
}));

const IconButton = styled(SecondaryButton)(() => ({
  padding: '10px 15px 10px 10px',
  textAlign: 'left',
  height: 'auto',
  '.MuiButton-startIcon': {
    marginLeft: 0,
  },
  '.btnText': {
    position: 'relative',
    top: '1px',
  },
}));

const IconPrimaryButton = styled(IconButton)(() => ({
  color: '#FFFFFF',
  backgroundColor: '#0099FA',
  '& svg path': {
    stroke: '#FFFFFF',
  },
  '&:hover': {
    backgroundColor: '#0069FF',
  },
  '&.Mui-disabled': {
    backgroundColor: '#EBEBEC',
    color: '#888B8D',
  },
}));

const IconGradientButton = styled(IconPrimaryButton)(() => ({
  padding: 10,
  backgroundColor: 'rgba(9, 10, 11, .2)',
  border: 'none',
  '& svg path': {
    stroke: 'inherit',
  },
  '&:hover': {
    backgroundColor: 'rgba(9, 10, 11, .4)',
    border: 'none',
  },
}));

const TextButton = styled(PrimaryButton)(() => ({
  backgroundColor: 'transparent',
  color: '#0099FA',
  '&:hover': {
    backgroundColor: 'transparent',
    border: 'none',
    color: '#0099FA',
  },
}));

const Button = ({ variant = 'primary', children, ...props }) => {
  let CustomButton;
  switch (variant) {
    case 'primary':
      CustomButton = PrimaryButton;
      break;
    case 'secondary':
      CustomButton = SecondaryButton;
      break;
    case 'text':
      CustomButton = TextButton;
      break;
    case 'icon':
      CustomButton = IconButton;
      break;
    case 'iconPrimary':
      CustomButton = IconPrimaryButton;
      break;
    case 'iconGradient':
      CustomButton = IconGradientButton;
      break;
  }

  return (
    <CustomButton variant="outlined" {...props}>
      <div className="btnText">{children}</div>
    </CustomButton>
  );
};

Button.propTypes = propTypes;
export default Button;
