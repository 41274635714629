import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { allGamesSelector, sortBy } from 'store/slices/allGames';

import { triggerPlausibleEvent } from 'utils/plausible';

import Select from 'components/UI/Select/Select';

import { plausibleEvents } from 'components/config/plausibleEvents';
import { Sort } from 'components/config/sort';

const SortBy = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const value = useSelector(allGamesSelector.getSortBy);

  const handleChange = useCallback(
    (_, value) => {
      dispatch(sortBy(value));

      triggerPlausibleEvent({
        name: plausibleEvents.sorting,
        props: {
          'sorting-type': value,
          page: `all-games-${provider}`,
        },
      });
    },
    [provider],
  );

  return (
    <Select
      handleSelect={handleChange}
      title="Sort By"
      label="sortBy"
      items={Sort}
      value={value}
    />
  );
};

export default SortBy;
