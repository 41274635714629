import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import classNames from 'classnames';

import { viewModeSelector } from 'store/slices/viewMode';

import { triggerPlausibleEvent } from 'utils/plausible';
import { VIEW_MODE } from 'utils/viewMode';

import DownloadMaterials from 'components/DownloadMaterials/DownloadMaterials';
import GameDemo from 'components/GameDemo/GameDemo';
import Button from 'components/UI/Button/Button';
import Dialog from 'components/UI/Dialog/Dialog';
import Features from 'components/pages/Game/Features/Features';

import {
  FEATURES_DATA,
  MATERIALS,
  STRUCTURE,
  THEMES,
} from 'components/config/bingo';
import { bingoPath } from 'components/config/cloud';
import { plausibleEvents } from 'components/config/plausibleEvents';

import { ReactComponent as Ball940 } from 'images/Bingo/Ball940.svg';
import { ReactComponent as Ball1280 } from 'images/Bingo/Ball1280.svg';
import Banner940 from 'images/Bingo/Banner940x414.jpg';
import Banner1441 from 'images/Bingo/Banner1441x635.jpg';
import Card1 from 'images/Bingo/Card1.jpg';
import Card2 from 'images/Bingo/Card2.jpg';
import { ReactComponent as Community } from 'images/Bingo/Community.svg';
import { ReactComponent as Game } from 'images/Bingo/Game.svg';
import { ReactComponent as Gamification } from 'images/Bingo/Gamification.svg';
import Laptop from 'images/Bingo/Laptop.jpg';
import { ReactComponent as Players } from 'images/Bingo/Players.svg';
import { ReactComponent as Social } from 'images/Bingo/Social.svg';
import { ReactComponent as EnglishIcon } from 'images/Icons/Flags/English.svg';
import { ReactComponent as GameIcon } from 'images/Icons/Game.svg';

import styles from 'components/pages/Bingo/Bingo.module.scss';
import stylesBanner from 'components/pages/Home/Banner/Banner.module.scss';

const eventPage = 'bingo';

const Bingo = () => {
  const viewMode = useSelector(viewModeSelector.getViewMode);

  const [openDemo, setOpenDemo] = useState(false);

  const handleOpenDemo = useCallback(() => {
    setOpenDemo(true);

    triggerPlausibleEvent({
      name: plausibleEvents.playDemo,
      props: {
        page: eventPage,
      },
    });
  }, []);
  const handleCloseDemo = useCallback(() => setOpenDemo(false), []);

  return (
    <>
      <div className={stylesBanner.wrapper}>
        <div className={stylesBanner.video}>
          <img
            src={viewMode === VIEW_MODE.SIMPLE ? Banner940 : Banner1441}
            alt="Banner"
          />
        </div>
        <div className={styles.contentBanner}>
          <div className={stylesBanner.title}>
            <span>Bingo -</span> THE NEXT BIG THING IN iGAMING
          </div>
          <div className={stylesBanner.description}>
            For Big Numbers in Your Profits
          </div>
          <div className={stylesBanner.actions}>
            <DownloadMaterials
              variant="Primary"
              type="Bingo"
              structure={STRUCTURE}
              materials={MATERIALS}
              downloadFileName="Bingo"
              folderPath={`${bingoPath}/`}
              provider="bingo"
              trackEvent={{
                name: plausibleEvents.clickDownload,
                props: {
                  page: eventPage,
                },
              }}
              languageLabel="Room Theme"
            >
              Download materials
            </DownloadMaterials>
          </div>
        </div>
      </div>

      <div className={classNames(styles.content, styles.playDemoWrapper)}>
        <div className={styles.text}>
          <div className={styles.title}>
            BINGO BY EVOPLAY - INNOVATION UNLOCKED IN A NEW SECTOR
          </div>
          <div className={styles.description}>
            <p>
              We&apos;ve crafted a product that redefines bingo with fresh touch
              while preserving its timeless charm of simplicity.
            </p>
            It’s good old bingo, innovated by EVOPLAY.
          </div>
        </div>
        <div className={styles.laptop}>
          <img src={Laptop} alt="Laptop" />
          <Button
            onClick={handleOpenDemo}
            variant="iconGradient"
            startIcon={<GameIcon />}
          >
            Play Demo
          </Button>
        </div>
      </div>

      <div className={styles.lightGreyBG}>
        <div className={classNames(styles.content, styles.bingoWayWrapper)}>
          <div className={styles.title}>Evoplay&apos;s bingo</div>
          <div className={styles.description}>
            Getting players engaged, the Bingo way.
          </div>
          <div className={styles.bingoWay}>
            <div className={styles.wayItem}>
              <Game />
              <div className={styles.wayItemDescription}>
                Simple gameplay and easy-to-grasp mechanic.
              </div>
            </div>
            <div className={styles.wayItem}>
              <Social />
              <div className={styles.wayItemDescription}>
                Social nature offering a more relaxed and collective style of
                gaming.
              </div>
            </div>
            <div className={styles.wayItem}>
              <Community />
              <div className={styles.wayItemDescription}>
                Sense of community via multiplayer gameplay.
              </div>
            </div>
            <div className={styles.wayItem}>
              <Players />
              <div className={styles.wayItemDescription}>
                Interactive environment for players.
              </div>
            </div>
            <div className={styles.wayItem}>
              <Gamification />
              <div className={styles.wayItemDescription}>
                Gamification: loyalty programs, rewards, and tournaments.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames(styles.content, styles.ballWrapper)}>
        {viewMode === VIEW_MODE.SIMPLE ? <Ball940 /> : <Ball1280 />}
      </div>

      <div className={styles.blueBG}>
        <div className={classNames(styles.content, styles.miniGameWrapper)}>
          <div className={styles.title}>
            MINI-GAMES FOR MAXIMUM ENTERTAINMENT
          </div>
          <div className={styles.cards}>
            <div className={styles.card}>
              <div>
                <div className={styles.cardNumber}>1</div>
                <div>
                  Based on the Evoplay slot and instant games, our mini-games
                  provide customisable options tailored to meet your objectives.
                </div>
              </div>
              <img src={Card1} alt="Card1" />
            </div>
            <div className={styles.card}>
              <div>
                <div className={styles.cardNumber}>2</div>
                <div>
                  Players can combine them with bingo games to enhance their
                  enjoyment, particularly during the start of bingo rounds.
                </div>
              </div>
              <img src={Card2} alt="Card2" />
            </div>
          </div>
        </div>
      </div>

      <Features data={FEATURES_DATA} title="Bingo Types" />

      <div className={classNames(styles.content, styles.themesWrapper)}>
        <div className={styles.title}>Room themes</div>
        <div className={styles.themes}>
          {THEMES.map((item) => (
            <div key={item.name} className={styles.theme}>
              <div className={styles.themeTag}>
                <span>{item.name}</span>
              </div>
              <img src={item.media} alt="theme" />
            </div>
          ))}
        </div>
      </div>

      <div className={styles.lightGreyBG}>
        <div
          className={classNames(styles.content, styles.technicalInfoWrapper)}
        >
          <div className={styles.title}>Technical information</div>
          <div className={styles.infoWrapper}>
            <div className={styles.infoBlock}>
              <div className={styles.infoSection}>
                <div className={styles.label}>Supported Languages:</div>
                <div className={styles.block}>
                  <EnglishIcon width={30} height={30} />
                </div>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoSection}>
                <div className={styles.label}>Bingo Types:</div>
                <div className={styles.block}>
                  <div className={styles.type}>50</div>
                  <div className={styles.type}>75</div>
                  <div className={styles.type}>90</div>
                </div>
              </div>
            </div>
            <div className={styles.infoBlock}>
              <div className={styles.infoSection}>
                <div className={styles.label}>Game Weight:</div>
                <div className={styles.block}>
                  <div className={styles.weight}>3 MB</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.description}>
            Introducing our revolutionary bingo and gaming solution designed to
            deliver an unparalleled experience to players across all platforms –
            whether online, mobile, or in bingo halls. With a single wallet and
            account management system, players can seamlessly navigate between
            different environments in real time. Our highly flexible bingo
            offering boasts a customizable UI and a diverse range of variants,
            including 90-ball, 75-ball, and 50-ball, catering to players
            worldwide. Through an intuitive in-game lobby and a sleek user
            interface, we prioritise user interaction and engagement, ensuring
            players keep coming back for more. Partnering with Evoplay means
            having a say in shaping the product roadmap and tailoring unique
            bingo experiences to align with your brand. Choose between our
            ready-made solution with configurations and analytics or opt for a
            fully customisable option for a tailored gaming experience.
            Experience simplicity and innovation firsthand while providing
            players with a rewarding gambling experience they won&apos;t forget.
          </div>
        </div>
      </div>

      <Dialog
        handleClose={handleCloseDemo}
        open={openDemo}
        options={{ type: 'opacity', hideScroll: true }}
      >
        <GameDemo src="https://api-kube.rc.evoent.tech/bingo/api/demo/room1" />
      </Dialog>
    </>
  );
};

export default Bingo;
