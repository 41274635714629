import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import { triggerPlausibleEvent } from 'utils/plausible';

import Button from 'components/UI/Button/Button';
import Switch from 'components/UI/Switch/Switch';

import { data } from 'components/config/documentation';
import { plausibleEvents } from 'components/config/plausibleEvents';

import { ReactComponent as DownloadIcon } from 'images/Icons/Download.svg';

import styles from './Documentation.module.scss';

const Documentation = () => {
  const [langCategory, setLangCategory] = useState({});
  const onChange = useCallback((category, checked) => {
    setLangCategory((prevState) => ({
      ...prevState,
      [category]: checked ? data[category].switch[1] : data[category].switch[0],
    }));
  }, []);

  const handleDownloadEvent = useCallback(
    (category) => () =>
      triggerPlausibleEvent({
        name: plausibleEvents.keyConversionDownload,
        props: {
          page: 'documentation',
          content: `${category.toLowerCase().replace(' ', '-')}-doc`,
        },
      }),
    [],
  );

  return (
    <div className={styles.wrapper}>
      {Object.keys(data).map((category) => (
        <div key={category}>
          <div className={styles.header}>
            <div className={styles.title}>{category}</div>
            {data[category]?.switch?.length > 1 && (
              <Switch
                from={data[category].switch[0]}
                to={data[category].switch[1]}
                onChange={(checked) => onChange(category, checked)}
              />
            )}
          </div>
          <div className={styles.content}>
            {data[category][langCategory[category] || 'English'].map((item) => (
              <Button
                key={`${langCategory[category] || 'English'}${item.title}`}
                variant="icon"
                target="_blank"
                rel="noreferrer"
                component={Link}
                startIcon={<DownloadIcon width={32} height={32} />}
                className={styles.btn}
                to={item.link}
                onClick={handleDownloadEvent(category)}
                download
              >
                {item.title}
              </Button>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Documentation;
