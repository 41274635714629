import Keycloak from 'keycloak-js';

import { config } from 'config/keycloak';

const _kc = new Keycloak(config[process.env.REACT_APP_VERSION]);

const initKeycloak = (onAuthenticatedCallback) => {
  _kc
    .init({
      onLoad: 'login-required',
      checkLoginIframe: false,
    })
    .then((authenticated) => {
      if (!authenticated) {
        return updateToken()
          .then(() => onAuthenticatedCallback())
          .catch(doLogin);
      }
      _kc.onTokenExpired = () => doLogout();
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const updateToken = () => _kc.updateToken(10).catch(console.error);
const doLogin = _kc.login;
const doLogout = _kc.logout;
const getToken = () => _kc.token;
const isLoggedIn = () => !!_kc.token;
const getUserProfile = () =>
  _kc
    .loadUserProfile()
    .then((data) => data)
    .catch(doLogin);

const getAcr = () => _kc.tokenParsed?.acr;
const getSessionId = () => _kc.tokenParsed?.session_state;

export {
  initKeycloak,
  doLogout,
  isLoggedIn,
  getToken,
  getUserProfile,
  updateToken,
  getAcr,
  getSessionId,
};
