import { ReactComponent as Bonus } from 'images/Icons/Bonus.svg';
import { ReactComponent as Jackpot } from 'images/Icons/Jackpot.svg';
import { ReactComponent as Prize } from 'images/Icons/Prize.svg';
import { ReactComponent as Promotions } from 'images/Icons/Promotions.svg';
import { ReactComponent as Quests } from 'images/Icons/Star.svg';
import { ReactComponent as Wheel } from 'images/Icons/Wheel.svg';
import bonusHouseLogo from 'images/Tutorial/bonus-house.png';

export const navigation = [
  {
    anchorId: 'tournaments',
    name: 'Tournaments',
    icon: <Promotions />,
  },
  {
    anchorId: 'prizes',
    name: 'Prize Drops',
    icon: <Prize />,
  },
  {
    anchorId: 'quests',
    name: 'Quests',
    icon: <Quests />,
  },
  {
    anchorId: 'bonuses',
    name: 'Bonuses',
    icon: <Bonus />,
  },
  {
    anchorId: 'jackpots',
    name: 'Progressive Jackpots',
    icon: <Jackpot />,
  },
  {
    anchorId: 'fortunewheel',
    name: 'Wheel of Fortune',
    icon: <Wheel />,
  },
];

export const data = {
  bonusHouse: {
    title: 'Bonus House',
    description:
      'Bonus House is your ultimate destination for top-tier promotional tools from Evoplay.\n\nYou get a comprehensive set of solutions tailored to boost your casino metrics at every point.',
    logo: bonusHouseLogo,
    navigation,
    contents: [
      {
        ...navigation[0],
        description:
          'Boost engagement and retention with Evoplay tournaments. Enhance player gaming experiences through competitive elements and extra rewards. Our tournaments drive increased participation and longer-lasting connections between your platform and iGaming audience.',
        items: [
          'An array of diverse mechanics.',
          'Tournaments can be set up in any currency.',
          'Network and local tournaments.',
          "Automatic players' participation in the tournament.",
          'Available for slots and instant games.',
          'Real-time updates and a final leaderboard for players.',
          'Optional number of participating games.',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/Tournaments+new+v3.mp4',
      },
      {
        ...navigation[1],
        description:
          'Experience the transformative power of our cutting-edge tool that injects an element of anticipation by randomly awarding cash to players. Unpredictability introduces an enticing factor, keeping them interested. This contributes to increased session durations, ultimately positively influencing revenue and player lifetime value.',
        items: [
          'The tool is exclusively available for partners with integrated autopayments.',
          'The number of participating games varies from one to several.',
          'Prize Drops can be network or local.',
          "The number of a player's wins during the day can be limited.",
          'A Prize Drops campaign may include both slots and instant games.',
          'Prize Drops can be activated together with tournaments or separately. ',
          'The Prize Drops tool is customised for each campaign.',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/PrizeDrops+new+v4.mp4',
      },
      {
        ...navigation[2],
        description:
          'Upgrade your online casino by incorporating the Evoplay Quests to enrich the gamification aspect. Let your players embark on missions, empowering them to accomplish goals and feel like champions. This not only enhances player retention but also increases their lifetime value.',
        items: [
          'Quests can be either network or local.',
          'Three types of rewards for players.',
          'Available for slots and instant games.',
          'Intuitive quest widget.',
          'Optional number of participating games.',
          "A progress bar showing the player's completion of a specific quest.",
          'Two quest mechanics.',
          "Automatic players' participation.",
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/Quests.mp4',
      },
      {
        ...navigation[3],
        description:
          'Introducing a dynamic promotional tool designed to reward players with Bonus Spins and Bonus Rounds. Elevate the gaming experience and engage players in a gameplay filled with additional perks. Attract a broader audience and maintain the interest of your current user base.',
        items: [
          "You can use the tool through API integration or Evoplay's Back Office.",
          '2 types of Bonus Spins are available.',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/FreespinsRounds+new+v3.mp4',
      },
      {
        ...navigation[4],
        description:
          'Progressive Jackpots are the ultimate catalyst for heightened player engagement, offering an enticing blend of excitement and the promise of an ever-growing jackpot. It ensures a rewarding experience that is a potent strategy to extend gameplay and foster long-term connections with the iGaming audience.',
        items: [
          'Jackpots are customised for casinos.',
          "The jackpot amount is updated in accordance with the casino's settings.",
          'The jackpot amount varies based on individual partner specifications.',
          'Three jackpot widget designs are available for partners.',
          'Jackpots are formed by the bets made by players on a particular online casino site.',
          'Evoplay features 3 games with a progressive jackpot.',
          'You can select the percentage of the bet that contributes to the jackpot pool.',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/Progressive+Jackpot.mp4',
      },
      {
        ...navigation[5],
        description:
          'Make your retention and critical business metrics soar with the power of Wheel of Fortune! The driver behind the tool is the anticipation element,\n' +
          'a proven booster for player engagement. Moreover, we take it to another level with an element of intrigue as iGamers spin the wheel to win the prize. Ensuring longer player LTV, increased metrics and brand differentiation, integrating Evoplay’s Wheel of Fortune will turn a profit wheel\n' +
          'in your favour!',
        items: [
          'Customisable campaign duration, prize number and value, budget, accepted currencies, and minimum bet requirements.',
          'Possible prizes include free rounds, cash prizes, and custom prizes.',
          'Available for all Evoplay’s games.',
          'The chance of winning is 50%.',
          'Random distribution of prizes (depending on the prize pool for the day)',
          'Backed up with necessary marketing materials.',
          'Configured on our side (no additional action needed).',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/ee_wheel_tutorial_V1+(1).mp4 ',
      },
    ],
  },
  backOffice: {
    title: 'Back Office',
    navigation: null,
    contents: [
      {
        description:
          "Monitor your analytics effortlessly through Back Office, where you'll discover valuable insights into your key metrics. Keep tabs on your invoicing and statistics and gain a deeper understanding of your business performance.",
        items: [
          'Comprises essential data on key metrics.',
          'Individual user payout statistics.',
          'Access to invoice details.',
          'Enables the assignment of bonuses to players.',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/BackOffice.mp4',
      },
    ],
  },
  bingo: {
    title: 'Bingo',
    navigation: null,
    contents: [
      {
        description:
          'Grow your audience with Evoplay’s bingo, which combines innovation and timeless simplicity. This product redefines bingo by integrating engaging mini-games based on our popular slots and instant games. The result is continuous entertainment for players and increased playing time.',
        items: [
          'Most popular bingo types: 90-ball, 75-ball, and 50-ball.',
          'Just wallet API integration is required for bingo support.',
          'Freedom to customise bingo patterns, themes, prizes and rakes.',
          'You can choose games from Evoplay’s portfolio to integrate into bingo.',
        ],
        mediaUrl:
          'https://evoplay-cloud.s3.eu-central-1.amazonaws.com/Tutorials/EE_Bingo_Tutorial_V1.mp4',
      },
    ],
  },
};
