import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  finish,
  initialize,
  lisbonDialogSelector,
} from 'store/slices/lisbonDialog';

import { findPatchPages } from 'utils/pages';
import { triggerPlausibleEvent } from 'utils/plausible';

import Dialog from 'components/UI/Dialog/Dialog';

import {
  paperStyles,
  predictionCard,
  steps,
} from 'components/config/lisbonDialog';
import { PAGE_PATH } from 'components/config/pages';
import { plausibleEvents } from 'components/config/plausibleEvents';

import styles from './LisbonDialog.module.scss';

const LisbonDialog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [activeVideoLoaded, setActiveVideoLoaded] = useState(false);

  const isVisible = useSelector(lisbonDialogSelector.getVisible);

  const currentStep = useMemo(() => steps[activeStep], [activeStep]);

  const eventProps = useMemo(
    () => ({
      'popup-type': `popup-${activeStep + 1}`,
    }),
    [activeStep],
  );

  const isLastStep = useMemo(
    () => activeStep === steps.length - 1,
    [activeStep],
  );

  const handleNextStep = useCallback(() => {
    triggerPlausibleEvent({
      name: plausibleEvents.sbcPopupButtonClick,
      props: eventProps,
    });

    if (activeVideoLoaded) {
      setActiveVideoLoaded(false);
    }

    if (!isLastStep) {
      setActiveStep((prev) => prev + 1);
      return;
    }

    dispatch(finish());
    navigate(
      findPatchPages(PAGE_PATH.PROMOTIONS).replace(':provider', 'evoplay'),
    );
  }, [eventProps, activeVideoLoaded, isLastStep]);

  const handleClose = useCallback(() => {
    dispatch(finish());

    triggerPlausibleEvent({
      name: plausibleEvents.sbcPopupButtonClose,
      props: eventProps,
    });
  }, [eventProps]);

  const handleVideoLoaded = useCallback(() => setActiveVideoLoaded(true), []);

  const actions = useMemo(
    () => [
      {
        title: currentStep.actionTitle,
        action: handleNextStep,
        props: {
          sx: {
            width: 'auto',
            minWidth: '180px',
          },
          'data-testid': 'lisbon-dialog-btn',
        },
      },
    ],
    [currentStep, handleNextStep],
  );

  useEffect(() => {
    dispatch(initialize());
  }, []);

  return (
    <Dialog
      open={isVisible}
      actions={actions}
      handleClose={handleClose}
      options={{
        type: 'opacity',
        hideScroll: true,
        paperStyles,
      }}
      data-testid="lisbon-dialog-root"
    >
      <div className={styles.content}>
        <div className={styles.title} data-testid="lisbon-dialog-title">
          {currentStep.title}
        </div>
        {currentStep.video && (
          <div
            className={`${styles.videoWrapper} ${activeVideoLoaded ? styles.loaded : ''}`}
            data-testid="lisbon-dialog-video-wrapper"
          >
            <ReactPlayer
              url={currentStep.video}
              width="100%"
              height="100%"
              onReady={handleVideoLoaded}
              playing
              muted
              loop
            />
          </div>
        )}
        <div className={styles.cardWrapper}>
          <div
            className={`${styles.card} ${!isLastStep ? styles.hidden : ''}`}
            data-testid="lisbon-dialog-prediction-card"
          >
            <div className={styles.cardImgWrapper}>
              <img
                src={predictionCard.src}
                className={styles.cardImg}
                alt={predictionCard.title}
              />
              <div className={styles.cardTitle}>{predictionCard.title}</div>
            </div>
            <div className={styles.cardText}>{predictionCard.text}</div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default LisbonDialog;
