import sidebarSlice, { sidebarMiddleware } from './slices/sidebar';
import { configureStore } from '@reduxjs/toolkit';

import { api as gameServiceApi } from 'store/api/gameService/api';
import { api as tournamentServiceApi } from 'store/api/tournamentService/api';
import alertSlice from 'store/slices/alert';
import allGamesSlice, { allGamesMiddleware } from 'store/slices/allGames';
import certificateSlice from 'store/slices/certificate';
import documentsSlice from 'store/slices/documents';
import downloadMaterialsSlice from 'store/slices/downloadMaterials';
import downloadProcessSlice from 'store/slices/downloadProcess';
import lisbonDialogSlice from 'store/slices/lisbonDialog';
import onboardingSlice, { onboardingMiddleware } from 'store/slices/onboarding';
import roadmapSlice, { roadmapMiddleware } from 'store/slices/roadmap';
import tournamentPopupSlice, {
  tournamentPopupMiddleware,
} from 'store/slices/tournamentPopup';
import userSlice from 'store/slices/user';
import viewModeSlice from 'store/slices/viewMode';

export const store = configureStore({
  reducer: {
    sidebar: sidebarSlice,
    viewMode: viewModeSlice,
    allGames: allGamesSlice,
    roadmap: roadmapSlice,
    documents: documentsSlice,
    certificate: certificateSlice,
    alert: alertSlice,
    downloadMaterials: downloadMaterialsSlice,
    downloadProcess: downloadProcessSlice,
    onboarding: onboardingSlice,
    user: userSlice,
    tournamentPopup: tournamentPopupSlice,
    lisbonDialog: lisbonDialogSlice,
    [gameServiceApi.reducerPath]: gameServiceApi.reducer,
    [tournamentServiceApi.reducerPath]: tournamentServiceApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      sidebarMiddleware,
      allGamesMiddleware,
      roadmapMiddleware,
      onboardingMiddleware,
      tournamentPopupMiddleware,
      gameServiceApi.middleware,
      tournamentServiceApi.middleware,
    ),
});

export default store;
