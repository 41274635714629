import { createSlice } from '@reduxjs/toolkit';

import { getSessionId } from 'services/UserService';
import StorageCollection from 'utils/storage/StorageCollection';
import { getStorageData, setStorageData } from 'utils/storage/lisbonDialog';
import { getStorageData as getOnboardingStorageData } from 'utils/storage/onboarding';

const initialState = {
  visible: getStorageData().visible,
  initialOnboardingVisible: getOnboardingStorageData().visible,
};

const lisbonDialogSlice = createSlice({
  name: 'lisbonDialog',
  initialState,
  reducers: {
    initialize: (state) => {
      const sessionIdStorage = new StorageCollection('sessionId');
      const currentSessionId = getSessionId();

      if (sessionIdStorage.getAll()?.value !== currentSessionId) {
        if (!state.visible) {
          state.visible = true;
          setStorageData({ visible: true });
        }

        sessionIdStorage.add({ value: currentSessionId });
      }
    },
    finish: (state) => {
      state.visible = false;
      setStorageData({ visible: false });
    },
  },
});

export const lisbonDialogSelector = {
  getVisible: ({ lisbonDialog }) =>
    !lisbonDialog.initialOnboardingVisible && lisbonDialog.visible,
};

export const { initialize, finish } = lisbonDialogSlice.actions;
export default lisbonDialogSlice.reducer;
