import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  allGamesSelector,
  clearFilters,
  setFilters,
} from 'store/slices/allGames';
import { viewModeSelector } from 'store/slices/viewMode';

import { triggerPlausibleEvent } from 'utils/plausible';

import Select from 'components/UI/Select/Select';
import ToggleFilters from 'components/pages/AllGames/ToggleFilters/ToggleFilters';

import data from 'components/config/filters';
import { plausibleEvents } from 'components/config/plausibleEvents';

import { ReactComponent as ClearIcon } from 'images/Icons/Clear.svg';

import styles from './Filters.module.scss';

const hasOneFilter = (filters) =>
  Object.keys(filters).some((item) => filters[item].length > 0);

const Filters = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const viewMode = useSelector(viewModeSelector.getViewMode);
  const filters = useSelector(allGamesSelector.getFilters);
  const isVisible = useSelector(allGamesSelector.getVisible);

  const handleChange = useCallback(
    (label, value) => {
      dispatch(setFilters({ label, value }));

      triggerPlausibleEvent({
        name: plausibleEvents.filtering,
        props: {
          'filter-name': label,
          page: `all-games-${provider}`,
        },
      });
    },
    [provider],
  );

  const handleClearAll = useCallback((event) => {
    event.preventDefault();
    dispatch(clearFilters());
  }, []);

  if (!isVisible) {
    return (
      <div className={styles.clearWrapper}>
        <div className={styles.clear}>
          {hasOneFilter(filters) && (
            <>
              <ClearIcon />
              <a href="#" className={styles.clearBtn} onClick={handleClearAll}>
                Clear all
              </a>
            </>
          )}
        </div>
        <ToggleFilters title="Filters" />
      </div>
    );
  }

  return (
    <>
      <div className={styles.wrapper}>
        {data.map(({ title, label, width, countTags, ...props }) => (
          <Select
            key={title}
            handleSelect={handleChange}
            value={filters[label] || []}
            width={width[viewMode]}
            title={title}
            label={label}
            countTags={countTags[viewMode]}
            multiple
            {...props}
          />
        ))}
      </div>
      <div className={styles.clearWrapper}>
        <div className={styles.clear}>
          {hasOneFilter(filters) && (
            <>
              <ClearIcon />
              <a href="#" className={styles.clearBtn} onClick={handleClearAll}>
                Clear all
              </a>
            </>
          )}
        </div>
        <ToggleFilters />
      </div>
    </>
  );
};

export default Filters;
