import React, { useCallback, useMemo, useState } from 'react';

import classNames from 'classnames';
import PropTypes from 'prop-types';

import { ReactComponent as CopyIcon } from 'images/Icons/Copy.svg';
import { ReactComponent as CopyStrokeIcon } from 'images/Icons/CopyStroke.svg';

import styles from 'components/Copy/Copy.module.scss';

const propTypes = {
  copyText: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  iconType: PropTypes.oneOf(['fill', 'stroke']),
  text: PropTypes.string,
  copyStyles: PropTypes.string,
};

const Copy = ({
  copyText,
  text = 'ID ',
  iconType = 'fill',
  copyStyles = null,
}) => {
  const [isCopy, setIsCopy] = useState(false);

  const fillIcon = useMemo(() => iconType === 'fill', [iconType]);

  const Icon = useMemo(
    () => (fillIcon ? CopyIcon : CopyStrokeIcon),
    [fillIcon],
  );

  const copyToClipboard = useCallback(
    (event) => {
      event.preventDefault();
      navigator.clipboard.writeText(copyText).then(() => {
        setIsCopy(true);
        setTimeout(() => setIsCopy(false), 2000);
      });
    },
    [copyText],
  );

  return (
    <span
      className={styles.wrapper}
      onClick={copyToClipboard}
      data-testid="copy-root"
    >
      <span className={styles.text} data-testid="copy-text-content">
        {text}
        {copyText}
      </span>
      {isCopy ? (
        <span
          className={classNames(styles.checkIcon, styles[iconType])}
          data-testid="copy-icon-check"
        />
      ) : (
        <Icon
          className={classNames(styles.copy, styles[iconType], copyStyles)}
          data-testid={fillIcon ? 'copy-icon-fill' : 'copy-icon-stroke'}
        />
      )}
    </span>
  );
};

Copy.propTypes = propTypes;
export default Copy;
