import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import throttle from 'lodash.throttle';

import { search } from 'store/slices/allGames';

import { triggerPlausibleEvent } from 'utils/plausible';

import SearchInput from 'components/UI/SearchInput/SearchInput';

import { plausibleEvents } from 'components/config/plausibleEvents';

import styles from './Search.module.scss';

const Search = () => {
  const dispatch = useDispatch();
  const { provider } = useParams();

  const [searched, setSearched] = useState(false);

  const handleAutocomplete = useCallback(
    throttle((event) => {
      event.stopPropagation();
      event.preventDefault();
      dispatch(search(event.target.value.toLowerCase().trim()));

      if (!searched) {
        triggerPlausibleEvent({
          name: plausibleEvents.search,
          props: {
            page: `all-games-${provider}`,
          },
        });

        setSearched(true);
      }
    }, 500),
    [searched, provider],
  );

  useEffect(() => {
    searched && setSearched(false);
  }, [provider]);

  useEffect(() => {
    return () => dispatch(search(''));
  }, []);

  return (
    <SearchInput
      handleChange={handleAutocomplete}
      customStyle={styles.wrapper}
      placeholder="Search by name or ID"
    />
  );
};

export default Search;
