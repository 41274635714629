export const plausibleEvents = {
  login: 'log+in',
  clickDownload: 'click+download',
  keyConversionDownload: 'key+conversion+download',
  playDemo: 'play+demo',
  watchTutorial: 'watch+tutorial',
  filtering: 'filtering',
  sorting: 'sorting',
  search: 'search',
  sbcPopupButtonClick: 'sbc+popup+button+click',
  sbcPopupButtonClose: 'sbc+pop+up+close',
};
