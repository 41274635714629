import { useEffect } from 'react';

const usePlausible = (onInit) => {
  useEffect(() => {
    if (process.env.REACT_APP_VERSION === 'prod') {
      const head = document.querySelector('head');
      const script1 = document.createElement('script');
      const script2 = document.createElement('script');

      script1.setAttribute(
        'src',
        'https://plausible.io/js/script.pageview-props.js',
      );
      script1.setAttribute('data-domain', 'client-area.evoplay.games');
      if (window.screen) {
        script1.setAttribute('event-screen', window.screen.width);
      }
      script1.defer = true;

      script2.type = 'text/javascript';
      script2.text =
        'window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }';

      head.appendChild(script1);
      head.appendChild(script2);

      onInit?.();

      return () => {
        head.removeChild(script1);
        head.removeChild(script2);
      };
    }
  }, []);
};

export default usePlausible;
